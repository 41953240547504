import React from "react";
import twitter from "../../assets/icons/twitter.svg";
import dextools from "../../assets/icons/dextools.png";
import telegram from "../../assets/icons/telegram.png";
import gitbook from "../../assets/icons/gitbook.svg";
import "./SocialButtons.css";
import useWindowDimensions from "../useWindowDimensions";
import { useNavigate } from "react-router-dom";
function SocialButtons({ navbar, hero }) {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  if (hero && width < 500) return null;

  return (
    <div className={navbar ? "social-wrapper navbar-social" : "social-wrapper"}>
      <div>
        <a
          href="https://twitter.com/xsnipererc20?s=21&t=FYoRmM6eBZYrjUTu7BUehA"
          target="__blank"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a href="https://t.me/Xsniper_erc" target="__blank">
          <img
            src={telegram}
            alt="telegram"
            style={{ width: "36px", height: "36px" }}
          />
        </a>
        <a
          href="https://www.dextools.io/app/en/ether/pair-explorer/0xeadc9cd82305e7710a8b1320d30a0110a10cc249"
          target="__blank"
        >
          <img
            src={dextools}
            alt="dextools"
            style={{ width: "36px", height: "36px" }}
          />
        </a>
        {/* <a
          href="https://x-sniper.gitbook.io/x-sniper-litepaper/"
          target="__blank"
        >
          <img
            src={gitbook}
            alt="gitbook"
            style={{ width: "36px", height: "36px" }}
          />
        </a> */}
      </div>
      {navbar && (
        <>
          <button
            className="button-big navbar-main-button"
            onClick={() => {
              console.log("whitepaper");
              navigate("/whitepaper");
            }}
          >
            White Paper
          </button>
          <a
            href="https://x-sniper.gitbook.io/x-sniper-litepaper/"
            target="__blank"
          >
            <button
              style={{ marginLeft: "10px" }}
              className="button-big navbar-main-button"
            >
              GitBook
            </button>
          </a>
        </>
      )}
    </div>
  );
}

export default SocialButtons;
