import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Landing.css";
import heroImage from "../../assets/icons/hero-image.png";
import SocialButtons from "../../components/SocialButtons/SocialButtons";
import whyus1 from "../../assets/icons/whyus1.png";
import whyus2 from "../../assets/icons/whyus2.png";
import whyus3 from "../../assets/icons/whyus3.png";
import Footer from "../../components/Footer/Footer";
import heroBlob from "../../assets/icons/hero-blob.png";
import feature1 from "../../assets/icons/feature1.png";
import aboutBlob from "../../assets/icons/about-fog.png";
import useWindowDimensions from "../../components/useWindowDimensions";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Landing() {
  const whyUsData = [
    {
      image: whyus1,
      title: "Enabling quick trade",
      desc: "We offer seamless and rapid execution of trades. Set your parameters, capitalize on market movements swiftly, and never miss a profitable opportunity. Simplify your trading experience and gain a competitive edge with our lightning-fast execution capabilities.",
    },
    {
      image: whyus2,
      title: "Risk Analysis",
      desc: "We use advanced algorithms and data analytics to evaluate the dynamic cryptocurrency market. Users gain comprehensive risk reports to make informed decisions, mitigate losses, and optimize their strategies with confidence. Safeguard your assets and navigate the crypto landscape with precision.",
    },
    {
      image: whyus3,
      title: "X Bridge",
      desc: "Enabling bridging between multiple blockchains via telegram bot coming soon.",
    },
  ];
  const featuresData = [
    {
      title: "Sniper Bot",
      desc: `The sniper bot is designed to capitalize on precise market opportunities, this bot executes trades with speed and accuracy, aiming to take advantage of short-term price fluctuations. With customizable settings and real-time monitoring, our state-of-the-art Sniper Bot empowers traders to enhance their trading strategies and amplify profits.`,
      img: feature1,
    },
    {
      title: "Wallet Analyser",
      desc: `The Wallet Analyzer is a powerful tool designed to provide crypto investors with valuable insights and comprehensive analysis of their cryptocurrency wallets. With user-friendly features and real-time data, this tool offers a detailed breakdown of portfolio holdings, historical performance, and potential risks. By empowering users with in-depth analytics, the Wallet Analyzer assists in making informed investment decisions, optimizing asset allocations, and maximizing overall portfolio growth.`,
      img: feature1,
    },
    {
      title: "Wallet Tracking",
      desc: `The Wallet Tracker is your ultimate companion for managing and monitoring your cryptocurrency holdings. This intuitive tool allows you to seamlessly track your various wallets in one centralized platform, providing real-time updates on balances, transaction history, and market values. With its user-friendly interface and advanced features, the Wallet Tracker simplifies portfolio management, enabling you to stay organized and make well-informed decisions about your crypto assets. Take charge of your investments and navigate the crypto market with confidence using the Wallet Tracker.`,
      img: feature1,
    },
    {
      title: "Whale Tracker",
      desc: `Whale Wallet Tracking is your window into the world of significant crypto players. Our platform specializes in monitoring and analyzing whale wallets - those holding large amounts of cryptocurrencies. By providing real-time data and insights, we empower traders and investors to follow the moves of these influential entities. Stay informed about whale activities, identify potential market trends, and make better-informed decisions for your own crypto journey. Join us and gain an edge in the crypto market with our Whale Wallet Tracking service.`,
      img: feature1,
    },
    {
      title: "Contract Scanner",
      desc: `X-scanner dedicated to identifying and detecting potentially harmful smart contracts within the blockchain ecosystem. Using advanced security algorithms and deep code analysis, the platform aims to protect users and developers from engaging with malicious contracts that could lead to financial losses or security breaches. By providing real-time scanning and risk assessments, Scan Malicious Contract helps ensure a safer and more secure environment for participants in the crypto space.`,
      img: feature1,
    },
  ];
  const basicFeatures = [
    "3 Wallet Sniping",
    "Analyzing Basic Contracts",
    "Tracking Whales",
    "Scanning for 5 High-Volume ETH Tokens",
    "Revenue Sharing (need to holder of token)",
  ];
  const premiumFeatures = [
    "10 Wallet Sniping",
    "Copy Trade & God Mode",
    "Thorough Contract Analysis & Whale Tracking",
    "Identifying the 10-20 Most Traded/High-Volume Tokens",
    "Revenue Sharing",
  ];
  const tokenomicsData = [
    {
      title: "Token Supply",
      desc: "1,000,000,000",
    },
    {
      title: "Tax",
      desc: "5/5",
    },
    {
      title: "Max Txn",
      desc: "1%",
    },
    {
      title: "Max Wallet",
      desc: "2%",
    },
    {
      desc: "Automated burn in 25 minutes",
      title: "Burn",
    },
    {
      desc: "Automated Ai Volume Generator",
      title: "Volume",
    },
  ];
  const [featureCount, setFeatureCount] = useState(0);
  const { width } = useWindowDimensions();

  const handleGetStarted = () => {
    // window.open(
    //   "https://t.me/Xsnipe_Bot",
    //   "_blank" // <- This is what makes it open in a new window.
    // );
  };

  useEffect(() => {
    if (width < 1200) {
      const interval = setInterval(() => {
        // Update the variable here
        setFeatureCount((prevValue) => (prevValue + 1) % 5);
      }, 3000);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(interval);
      };
    }
  }, [width]);

  const handleFeature = (index) => {
    setFeatureCount(index);
  };
  return (
    <>
      <Navbar />
      <div className="hero-section" id="home">
        <img src={heroBlob} alt="hero-blob" className="hero-blob" />
        <div className="hero-bg"></div>

        <div className="hero-content">
          <div className="hero-left">
            <h1 className="hero-title">Unleash Your Trading Potential</h1>
            <p>
              Trade Smarter, Trade Faster: Embrace Our Crypto Tools and master
              the Markets with Our State-of-the-Art Crypto Bots.
            </p>
            <div className="hero-buttons">
              <a target="__blank" href="https://t.me/Xsnipe_Bot">
                <button className="button-big" onClick={handleGetStarted}>
                  Get Started
                </button>
              </a>
              <SocialButtons hero={true} />
            </div>
            <p className="contract_desc">Contract Address:</p>
            <p className="contract_address">
              <a
                target="__blank"
                href="https://etherscan.io/token/0x1147fD6fd5e63C2F3d03B2D84186370077fF4538"
              >
                0x1147fD6fd5e63C2F3d03B2D84186370077fF4538
              </a>
            </p>
          </div>
          <div className="hero-img">
            <img src={heroImage} alt="hero-img" />
          </div>
        </div>
      </div>
      <div className="about-section" id="about-us">
        <div className="about-content">
          <span>
            <img src={aboutBlob} alt="about-blob" className="about-blob" />
          </span>
          <div>
            <h1>ABOUT US</h1>
            <p>
              At X-sniper, we empower traders to trade smarter and achieve
              better results in the dynamic world of cryptocurrencies. Our
              platform offers a comprehensive suite of cutting-edge crypto tools
              and state-of-the-art bots, designed to master the markets and
              optimize your trading strategies. Whether you are a seasoned
              trader or just starting, X-sniper is your go-to partner for
              unlocking your full trading potential and staying ahead of the
              game. Join us on this exciting journey and revolutionize your
              crypto trading experience today.
            </p>
          </div>
        </div>
      </div>
      <div className="features-section" id="tools">
        <h1>ECOSYSTEM</h1>
        {width > 1280 && (
          <div className="features-wrapper">
            {featuresData.map((feature, index) => {
              if (index === featureCount)
                return (
                  <div className="features-content" key={index}>
                    <h2>{feature.title}</h2>
                    <p>{feature.desc}</p>
                    <img src={feature1} alt="feature-img" />
                  </div>
                );
            })}

            <div className="feature-selector" dir="rtl">
              <ul dir="rtl">
                {featuresData.map((feature, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleFeature(index)}
                      className={featureCount === index ? "feature-active" : ""}
                    >
                      {feature.title}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {width < 1280 && (
          <>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, Autoplay]}
              autoplay={{ delay: 3000 }}
              slidesPerView={1}
              width={width}
              spaceBetween={50}
              scrollbar={{ draggable: true }}
              pagination={{ clickable: true }}
            >
              {featuresData.map((feature, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="features-content">
                      <h2>{feature.title}</h2>
                      <p>{feature.desc}</p>
                      <img src={feature1} alt="feature-img" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </div>
      <div className="tokenomics-section" id="tokenomics">
        <h1>Tokenomics</h1>
        <span>
          <img
            src={aboutBlob}
            alt="tokenomics-blob"
            className="tokenomics-blob"
          />
        </span>
        <div className="tokenomics-content">
          <div>
            {tokenomicsData.map((item, index) => {
              const className = `tokenomics-card div${index + 1}`;
              return (
                <div key={index} className={className}>
                  <p>{item.title}</p>
                  <h2 style={{ fontWeight: index >= 4 ? "500" : "600" }}>
                    {item.desc}
                  </h2>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="whyUs-section" id="features">
        <h1>WHY US?</h1>
        <div className="whyUs-content">
          {whyUsData.map((item, index) => {
            return (
              <div key={index} className="whyUs-card">
                <img src={item.image} alt="whyUs-img" />
                <h2
                  style={
                    index == 2
                      ? {
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }
                      : {}
                  }
                >
                  {item.title}{" "}
                  {index === 2 ? (
                    <span style={{ fontSize: "0.8rem" }}>
                      {"(Coming soon)"}
                    </span>
                  ) : (
                    <></>
                  )}
                </h2>
                <p>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="pricing-section">
        <div className="bronze-card" style={{ position: "relative" }}>
          <div className="pricing-header">
            <h1>Basic</h1>
            <p>*Free</p>
          </div>
          <p
            style={{
              position: "absolute",
              right: "11%",
              top: "43%",
              fontSize: "12px",
            }}
          >
            {"(must be a token holder)"}
          </p>
          <div className="pricing-body">
            <ul>
              {basicFeatures.map((feature, index) => {
                return <li key={index}>{feature}</li>;
              })}
            </ul>
          </div>
          <div style={{ marginTop: "60px" }} className="pricing-button">
            <a target="__blank" href="https://t.me/Xsnipe_Bot">
              <button className="button-big" onClick={handleGetStarted}>
                Get Started
              </button>
            </a>
          </div>
        </div>
        <div className="gold-card">
          <div className="pricing-header">
            <h1>Premium</h1>

            <p style={{ position: "relative" }}>
              <span
                style={{
                  fontSize: "16px",
                  top: "0px",
                  left: "-10px",
                  position: "absolute",
                }}
              >
                $
              </span>
              99
            </p>
          </div>
          <div className="pricing-body">
            <ul>
              {premiumFeatures.map((feature, index) => {
                return <li key={index}>{feature}</li>;
              })}
            </ul>
          </div>
          <div className="pricing-button">
            <button className="button-big">Coming soon</button>
          </div>
        </div>
      </div>
      <div className="community-section">
        <div className="community-content">
          <h1>Join Our Community</h1>
          <p>
            Join the x-sniper Community: Unlock the Secrets of Crypto Whales!
          </p>
          <a target="__blank" href="https://t.me/Xsnipe_Bot">
            <button className="button-big" onClick={handleGetStarted}>
              Get Started
            </button>
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Landing;
