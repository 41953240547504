import { useState } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "../../assets/icons/hamburger.svg";
import Logo from "../../assets/icons/logo.png";
//import { ReactComponent as Brand } from "../../assets/icons/logo.svg";
import "./Navbar.css";
import SocialButtons from "../SocialButtons/SocialButtons";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <NavLink to="/">
            <img src={Logo} alt="logo" />
          </NavLink>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img src={Hamburger} alt="menu-icon" />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <HashLink to="/#home" onClick={handleShowNavbar}>
                Home
              </HashLink>
            </li>
            <li>
              <HashLink to="/#about-us" onClick={handleShowNavbar}>
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink to="/#tools" onClick={handleShowNavbar}>
                Tools
              </HashLink>
            </li>
            <li>
              <HashLink to="/#features" onClick={handleShowNavbar}>
                Features
              </HashLink>
            </li>
            <li>
              <HashLink to="/#tokenomics" onClick={handleShowNavbar}>
                Tokenomics
              </HashLink>
            </li>
            <li>
              <SocialButtons navbar={true} />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
