import logo from "./logo.svg";
import Landing from "./pages/landing/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PDF from "./pages/PDF";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/whitepaper" element={<PDF />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
