import React from "react";
import SocialButtons from "../SocialButtons/SocialButtons";
import "./Footer.css";
function Footer() {
  return (
    <>
      <div className="footer-line"></div>
      <div className="footer-content">
        <p>© 2023 example. All Rights Reserved.</p>
        <div>
          <SocialButtons />
        </div>
      </div>
    </>
  );
}

export default Footer;
